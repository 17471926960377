import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/authSlice";
import commonSlice from "./reducers/commonSlice";
import departmentSlice from "./reducers/departmentSlice";
import establishmentSlice from "./reducers/establishmentSlice";
import professionSlice from "./reducers/professionSlice";
import specialitySlice from "./reducers/specialitySlice";
import bannerSlice from "./reducers/bannerSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    profession: professionSlice,
    establishment: establishmentSlice,
    common: commonSlice,
    speciality: specialitySlice,
    department: departmentSlice,
    banner: bannerSlice,
  },
});
