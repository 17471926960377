import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languagesList: [],
  servicesList: [],
  professionTypesList: [],
  specialitiesList: [],
  facilitiesList: [],
  zonesList: [],
  citiesList: [],
  establishmentTypesList: [],
};
const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    languagesListSuccess: (state, action) => {
      state.languagesList = action.payload;
    },
    languagesListFailed: (state, action) => {
      state.languagesList = [];
    },
    servicesListSuccess: (state, action) => {
      state.servicesList = action.payload;
    },
    servicesListFailed: (state, action) => {
      state.servicesList = [];
    },
    professionTypesListSuccess: (state, action) => {
      state.professionTypesList = action.payload;
    },
    professionTypesListFailed: (state, action) => {
      state.professionTypesList = [];
    },
    specialitiesListSuccess: (state, action) => {
      state.specialitiesList = action.payload;
    },
    specialitiesListFailed: (state, action) => {
      state.specialitiesList = [];
    },
    facilitiesListSuccess: (state, action) => {
      state.facilitiesList = action.payload;
    },
    facilitiesListFailed: (state, action) => {
      state.facilitiesList = [];
    },
    zonesListSuccess: (state, action) => {
      state.zonesList = action.payload;
    },
    zonesListFailed: (state, action) => {
      state.zonesList = [];
    },
    citiesListSuccess: (state, action) => {
      state.citiesList = action.payload;
    },
    citiesListFailed: (state, action) => {
      state.citiesList = [];
    },
    establishmentTypesListSuccess: (state, action) => {
      state.establishmentTypesList = action.payload;
    },
    establishmentTypesListFailed: (state, action) => {
      state.establishmentTypesList = [];
    },
  },
});

export const {
  languagesListSuccess,
  languagesListFailed,
  servicesListSuccess,
  servicesListFailed,
  professionTypesListSuccess,
  professionTypesListFailed,
  specialitiesListSuccess,
  specialitiesListFailed,
  facilitiesListSuccess,
  facilitiesListFailed,
  zonesListSuccess,
  zonesListFailed,
  citiesListSuccess,
  citiesListFailed,
  establishmentTypesListSuccess,
  establishmentTypesListFailed,
} = commonSlice.actions;
export default commonSlice.reducer;
