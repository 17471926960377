import { IconButton } from "@mui/material";
import React from "react";

const CustomIconButton = ({
  onClickAction,
  arialLabel,
  icon,
  size = "small",
}) => {
  return (
    <IconButton aria-label={arialLabel} onClick={onClickAction} size={size}>
      {icon}
    </IconButton>
  );
};

export default CustomIconButton;
