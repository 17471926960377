export const specialitiesData = [
  {
    id: 1,
    icon: "https://www.healine.com/assets/images/specialities/icon_1.png",
    name: "Speciality 1",
  },
  {
    id: 2,
    icon: "https://www.healine.com/assets/images/specialities/icon_2.png",
    name: "Speciality 2",
  },
];

export const languagesData = [
  {
    id: 1,
    language: "Arabic",
  },
  {
    id: 2,
    language: "English",
  },
  {
    id: 3,
    language: "Hindi",
  },
  {
    id: 4,
    language: "Malayalam",
  },
];

export const servicesData = [
  {
    id: 1,
    service: "Service 1",
  },
  {
    id: 2,
    service: "Service 2",
  },
  {
    id: 3,
    service: "Service 3",
  },
  {
    id: 4,
    service: "Service 4",
  },
];

export const establishmentTypes = [
  {
    id: 1,
    name: "Hospital",
  },
  {
    id: 2,
    name: "Clinic",
  },
];
export const zonesData = [
  {
    id: 1,
    name: "North Zone",
  },
  {
    id: 2,
    name: "South Zone",
  },
  {
    id: 3,
    name: "East Zone",
  },
];
export const cityList = [
  {
    id: 1,
    name: "Pune",
  },
  {
    id: 2,
    name: "Mumbai",
  },
];
export const yesNoOptions = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 0,
    name: "No",
  },
];
export const specTierData = [
  {
    id: 1,
    name: "Tier 1",
  },
  {
    id: 2,
    name: "Tier 2",
  },
];
export const rowsPerPageJsonData = [10, 20, 50, 100];
export const daysOfWeekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthListData = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];
