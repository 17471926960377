import { createSlice } from "@reduxjs/toolkit";

const localUserData = JSON.parse(localStorage.getItem("userDetails") || "{}");
const initialState = {
  userInfo: localUserData ? localUserData : null,
  userToken: localUserData ? localUserData.token : "",
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.userInfo = action.payload;
      state.userToken = action.payload.token;
    },
    logOutSuccess: (state, action) => {
      state.userInfo = null;
      state.userToken = "";
    },
  },
});

export const { loginSuccess, logOutSuccess } = authSlice.actions;
export default authSlice.reducer;
