import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useEffect } from "react";

import Storage from "../../utils/HandelLocalStorage";

const useStyles = makeStyles({
  label: {
    marginBottom: "11px",
    color: "#1E1E1E",
    fontSize: "16px",
    fontWeight: "500",
  },
  value: {
    color: "#000",
    paddingLeft: "5px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "end",
    marginRight: "20px",
    marginBottom: "20px",
    borderTop: "2px solid",
    marginTop: "20px",
    paddingTop: "20px",
  },
  backBtn: {
    justifyContent: "center",
    width: "98px",
    height: "44px",
    textTransform: "capitalize",
    background: "linear-gradient(180deg, #255480 0%, #173450 100%)",
  },
});

function Profile() {
  const classes = useStyles();
  const [user, setUser] = useState();

  const getUserInfo = () => {
    Storage.retrieveItem("userDetails").then((params) => {
      // console.log("Async User form axios", params);
      if (params === null || params === undefined || params === "") {
      } else {
        setUser(JSON.parse(params));
      }
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="min-width">
      <div
        className="d-flex mt-2rem mb-2 pb-2"
        style={{ borderBottom: "2px solid", alignItems: "baseline" }}
      >
        <h4 className="pagename-heading ml-0">Profile Page</h4>
      </div>
      {user && user?.name ? (
        <>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item lg={6}>
              <Typography className={classes.label}>Name</Typography>
              <Typography className={classes.value} variant="subtitle1">
                {user?.name}
              </Typography>
            </Grid>
            <Grid item lg={6}>
              <Typography className={classes.label}>Email</Typography>
              <Typography className={classes.value} variant="subtitle1">
                {user?.email}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.buttonWrapper}>
            <Grid item xs={2}></Grid>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Profile;
